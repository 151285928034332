import {ROLE} from './Roles';

export interface ITokenResponse {
	accountId: number;
	activation: boolean;
	admin: boolean;
	login: string;
	roleUser: ROLE;
	token: string;
	personalInfos: IPersonnalInfo;
}

export interface IPersonnalInfo {
	poste: string;
	rescueMail: string;
	telephone: string;
}

export interface IAccountValidationRequest {
	password: string;
	login: string;
	tokenId: string;
	personalInfosDto: IPersonnalInfo;
}

export interface IPasswordRequest {
	oldPassword: string;
	newPassword: string;
	userServiceType: string;
}

export interface IAccountIdentity {
	accountId: number;
	admin: boolean;
	entityName: string;
	firstName: string;
	lastName: string;
	roleType: string;
}

export interface IIdentity {
	accountId: number;
	lastName: string;
	firstName: string;
	login: string;
	mail: string;
}

export interface IAccountService {
	orderId: number;
	creationDate: Date;
	suppressionDate: Date;
	serviceType: UserServiceType;
	serviceState: UserServiceState;
	refused: boolean;
	adonixBillId: string;
}


export enum EnumUserServiceType {
	MAIL = 'MAIL',
	BALLADE = 'BALLADE',
	VISIO_SOFT = 'VISIO_SOFT',
	MER = 'MER',
	INTERNET= 'INTERNET',
}

export enum UserServiceType {
	MAIL = 'Messagerie @notaires.fr 1 Go',
	BALLADE = 'Ballade / VPN ADNOV',
	VISIO_SOFT = 'Logiciel de visioconférence',
	MER = 'Mon Espace Real',
    INTERNET= 'Internet',
}

export enum UserServiceState {
    NONE = 'Non activé',
    BEING_VALIDATED = 'En cours de validation',
    IN_CREATION = 'En cours de création',
    ACTIVE = 'Provisionné',
    DELETION_IN_PROGRESS = 'Suppression en cours',
    DELETED = 'Supprimé',
}

export interface ServiceFeesResponse {
	// Service Concerné
    serviceType: UserServiceType;
    // Durée minimum d'abonnement
    commitmentPeriod: string;
    // Calcul des frais restants
    fees: string;
    // Le prix de l'abonnement mensuel au service
    artPrice: string;
    // Date de création du service
	creationDate: Date;
}

export interface IUserServicePrice {
	serviceName: string;
	servicePrice: number;
	associatedServices?: IUserServicePrice[];
}

export interface IMailServiceRequest {
	accountId: number;
	webmailActivated: boolean;
	sslActivated: boolean;
}

export interface FormFieldValue {
	value: string;
	isError: boolean;
}

export const EMPTY_FORM_VALUE = {value: '', isError: false};
