import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import Axios from 'axios';
import VueSweetalert2 from 'vue-sweetalert2';
import LoginService from '@/services/LoginService';
import RoutageService from '@/services/RoutageService';
import CookieService from '@/services/CookieService';
// import BootstrapVue & IconsPlugin
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';

import UserServicesUtils from '@/services/UserServicesUtils';

// Import pour fonawesome-vue
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
// import des CSS de bootstrap
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import RestService from './services/RestService';
import Config from '@/config/config';
import DateUtils from '@/utils/DateUtils';
import DisplayEnumUtils from '@/utils/DisplayEnumUtils';

// import css MER
import '@/css/mer.css';
import '@/css/mer2_forms.css';

import 'sweetalert2/dist/sweetalert2.min.css';
import AlertService from '@/utils/AlertService';
import AuthUtils from '@/utils/AuthUtils';
import OfficeService from '@/services/OfficeService';
import GlobalService from '@/services/GlobalService';

export {store};

library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
// If you don't need the styles, do not connect
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

// Cette ligne permet de garder le JSessionId dans toute les requetes
Axios.defaults.withCredentials = true;

// Permet de désactiver le CACHE notamment pour IE.
Axios.defaults.headers.common = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
};
const yaml = require(/* webpackIgnore: true */'js-yaml');
// Les services
const loginService = new LoginService();
const routageService = new RoutageService();
const restService = new RestService();
const config: Config = new Config();
const cookieService = new CookieService();
const dateUtils = new DateUtils();
const officeService = new OfficeService();
const globalService = new GlobalService();
const displayEnumUtils = new DisplayEnumUtils();
const userServicesUtils = new UserServicesUtils();
export const services = { loginService, routageService, restService, config, cookieService, officeService};
export const utils = { dateUtils, displayEnumUtils, userServicesUtils };
export const configuration = config;
// pour avoir les config dans les vues
Vue.prototype.appConfig = config;
Vue.prototype.isAll = globalService.isAll;
Vue.prototype.isAdnov = globalService.isAdnov;
Vue.prototype.isAdsn = globalService.isAdsn;

// Définition des pages forbidden selon le profil de l'utilisateur
// A faire avant d'injecter router dans Vue
router.beforeEach((to, from, next) => {
    const isUserMer: boolean = store.getters.hasAnyAuthorityMer;
    const isConnectedUser: boolean = store.getters.isConnectedUser;

    if (!isConnectedUser && AuthUtils.hasToSaveUrl(to)) {
        store.commit('saveTargetUrl', window.location.pathname);
    }

    if (to.fullPath.endsWith('?fromEmail=true') || to?.path.startsWith('/first-connexion/')) {
        // Cas de la première connexion ou connexion via lien mail
        next();
    } else if ( to && isConnectedUser && isUserMer && store.getters.isMailRescueEmpty) {
        // Cas (specifique) Mail de secours d'un obligatoire
        // * Message d'avertissement
        AlertService.warning(store.getters.msgMailRescueWarn);
        if (to?.meta?.notfound !== undefined || to?.meta?.notfound) {
            next('/account/personal-info/personnal-info');
        } else if (to?.path !== '/account/personal-info/personnal-info') {
            console.warn('Adresse mail de secours est obligatoire.');
        } else {
            next();
        }
    } else if ( to && (isConnectedUser && !isUserMer && to?.meta?.requiresAuth) ||
    (isConnectedUser && to?.meta?.notaireOrAdmin && !store.getters.notaireOrAdmin)) {
        // Cas Utilisateur connecté mais non administrateur
        next('/access-denied');
    } else if ( to && !to?.meta?.requiresAuth) {
        next();
    } else if ( to && isConnectedUser && to?.meta?.requiresAuth) {
        next();
    } else {
        next('/');
    }
});

// Get document, or throw exception on error
try {
	Axios.get('/url.yml').then( (response: any) => {
    const doc = yaml.load(response.data);
    config.config.$api_url = doc['sig.base.url'];
    config.config.$mer_url = doc['mer.url'];
    config.config.$mer_services_by = doc['mer.services.by'];
    config.config.$directoy_visio_url = doc['url.directory.visio'];
    require('polyfill-library-node');
    new Vue({
			router,
			store,
			render: (h) => h(App),
		}).$mount('#app');
	});
} catch (e) {
  console.log(e);
}

