import {store} from '@/main';
import {IMostRecentNetwork} from '@/services/dto/IUserResponse';

export default class OfficeService {
    /**
     * Vide les champs MDP Ballade.
     */
    public isOfficeSdwan(): any {
        const mostRecentNetwork: IMostRecentNetwork = store.getters.fullUser.mostRecentNetwork;
        if (mostRecentNetwork ) {
             return mostRecentNetwork.isNetworkSdwan;
        } else {
            return null;
        }
    }
}
