export interface IColorAndStrengthLimited {
    idx: number;
    col: string;
}

export default class PatternUtils {

    public static SIG_PATTERNS = {
        // Format du préfixe pour les adresses mail, ne peut avoir 2 caractère spéciaux consécutif
        emailPrefix: '(?=^[a-zA-Z0-9][\\w_.-]{2,47}[a-zA-Z0-9]$)(?!^.*[._-]{2,}.*$)',
        // Format pour les adresses mail type notaire
        emailNotaire: '(?=^[a-zA-Z0-9][\\w_.-]{2,47}[a-zA-Z0-9]@[a-zA-Z0-9]+\\.[a-zA-Z]{2,3}$)' +
            '(?!^.*[._-]{2,}.*$)',
        // Format pour les adresses mail - ne peut commencer par un point ni ne peut avoir deux point consécutif
        // après le @
        email: /^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6})$/,
        phone: '^(0[0-9]{9}){0,1}$', // Format pour les numéros de téléphone
        mobilePhone: '^(0[6-7]{1}[0-9]{8}){0,1}$', // Format pour les numéros de téléphone portable
        name: '(?=^[\\w _.-]*$)(?!^.*[ _.-]{2,}.*$)', // Format pour les noms et prénoms
        // Format pour valider une chaîne de caractères avec accents français
        standard_string: '^(\\w| |\'|\\-|_|é|è|ê|ë|ï|î|ç|ù|û|ü|ô|ö|â|ä|à)*$',
        entityName: '(?=^[\\w _.-]*$)(?!^.*[ ._-]{2,}.*$)', // Format pour le nom des entités
        login: '(?=^[a-z0-9_.-]*$)(?!^.*[._-]{2,}.*$)', // Format de login
        // Format d'une ligne d'adresse
        addressLine: '^(\\w| |,|\'|\\-|_|.|&|é|è|ê|ë|ï|î|ç|ù|û|ü|ô|ö|â|ä|à)*$',
        postalCode: '^[0-9]{5}$', // Format d'un code postal
        town: '^[a-zA-Z \-]*$', // Format d'un nom de ville
        siret: '^[0-9]{14}$', // Format d'un SIRET
        invalidCharacter: '._!@^*=~&brvbar;+?#%,&lt;&gt;&pound;[]\\/&quot;{}():;-', // Format d'un SIRET
        password: {
            // Liste des caractères spéciaux autorisé par le SIG (* surchargé par le webservice sig)
            specialcharactersDefault: '._!@^*=~|+?#%,<>£[]\\/\"{}():;-',
        },
    };

    public static generateRandomPassword(specialCharacteres: string): string {
        // définition des caractères possible
        // var specials = 'SigConstantService.data.password.specialcharacters';

        let specials;
        if (typeof specialCharacteres === 'undefined' || specialCharacteres === '') {
            specials = ',;:!?.\/&\'()@_=+°\[\]|#\\$%£<>*-';
        } else {
            specials = specialCharacteres;
        }


        const lowercase = 'abcdefghijklmnopqrstuvwxyz';
        const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const numbers = '0123456789';

        const all = specials + lowercase + uppercase + numbers;

        function pick(stringToPickFrom: string, min: number, max?: number): string {
            let n;
            let chars = '';
            if (max === undefined) {
                n = min;
            } else {
                n = min + Math.floor(Math.random() * (max - min));
            }

            for (let i = 0; i < n; i++) {
                chars += stringToPickFrom.charAt(Math.floor(Math.random() * stringToPickFrom.length));
            }

            return chars;
        }

        // Credit to @Christoph: http://stackoverflow.com/a/962890/464744
        function shuffle(messageToShuffle: string): string {
            const array = messageToShuffle.split('');
            let tmp;
            let current;
            let top = array.length;
            if (top) {
                while (--top) {
                    current = Math.floor(Math.random() * (top + 1));
                    tmp = array[current];
                    array[current] = array[top];
                    array[top] = tmp;
                }
            }
            return array.join('');
        }

        return shuffle(pick(specials, 1)
            + pick(lowercase, 1)
            + pick(numbers, 1)
            + pick(uppercase, 1)
            + pick(all, 10, 20));
    }


    /**
     * Calcul de la force du MDP
     * @param {String} passwordToTest - Le mot de passe à tester
     * @param {String} specialCharacteres - caractere spéiaux autorisé
     * @return {number}
     */
    public static mesureStrength(passwordToTest: string, specialCharacteres: string): number {

        /*
        La frise doit refléter les contraintes suivantes :
            - le mot de passer doit comporter au moins 8 caractères ;
            - Il est composé de caractères d’au moins 3 des catégories suivantes :
                 Majuscule (A à Z) ; Minuscule (a à z) ;
                 Chiffre (0 à 9) ;
                 Caractère non alphabétique comme !, $, #, %. »
            */
        let force = 0;
        const minLength = 8;
        // var _regex = SigConstantService.data.password.regexSpecialcharacters; // Les caracteres spéciaux autorisés
        let specials;
        if (specialCharacteres === undefined || specialCharacteres === '') {
            specials = '[,;:!?.\/&\'()@_=+°\[\\]|#\\$%£<>*-]+';
        } else {
            specials = '[' + specialCharacteres.replace(']', '\\]') + ']+';
        }

        const regex = new RegExp(specials); // Les caracteres spéciaux autorisés

        // Résultats des catégories
        const lowerLetters = /[a-z]+/.test(passwordToTest);
        const upperLetters = /[A-Z]+/.test(passwordToTest);
        const numbers = /[0-9]+/.test(passwordToTest);
        const symbols = regex.test(passwordToTest);

        const flags = [lowerLetters, upperLetters, numbers, symbols];
        const passedMatches = grep(flags).length;

        function grep(items: boolean[]) {
            const filtered = [];
            const len = items.length;
            let i = 0;
            for (i; i < len; i++) {
                const item = items[i];
                if (item) {
                    filtered.push(item);
                }
            }
            return filtered;
        }

        force += 2 * passwordToTest.length + ((passwordToTest.length >= 10) ? 1 : 0);
        force += passedMatches * 10;

        // Si le mdp contient moins de X caractères, alors on met la frise au mini
        force = (passwordToTest.length < minLength) ? Math.min(force, 10) : force;

        // En fonction des catégories requise, on pondère
        force = (passedMatches === 1) ? Math.min(force, 10) : force; // Si 1 seule catégorie
        force = (passedMatches === 2) ? Math.min(force, 20) : force; // Si 2 catégories
        force = (passedMatches === 3) ? Math.min(force, 40) : force; // Si 3 catégories
        // - Au moins 3 cate + length, alors on est bon

        return force;
    }


    public static getColorAndStrengthLimited(strengthValue: number): IColorAndStrengthLimited {
        const colors = ['#E03930', '#F90', '#FF0', '#57A83F', '#57A83F'];
        let idx = 0;
        if (strengthValue <= 10) {
            idx = 0;
        } else if (strengthValue <= 20) {
            idx = 1;
        } else if (strengthValue <= 30) {
            idx = 2;
        } else if (strengthValue <= 40) {
            idx = 3;
        } else {
            idx = 4;
        }
        return {idx: idx + 1, col: colors[idx]};
    }

    /**
     * Permet de verfié l'ensemble du patterne
     * @param storeSpecialCharacteres
     */
    public static generateRegexValidityPwd(storeSpecialCharacteres: string): RegExp {
        const lowercaseLetters = 'a-z';
        const lookaheadLowercase = '(?=.*[' + lowercaseLetters + '])';
        const uppercaseLetters = 'A-Z';
        const lookaheadUppercase = '(?=.*[' + uppercaseLetters + '])';
        const numbers = '\\d';
        const lookaheadNumber = '(?=.*' + numbers + ')';
        const lookaheadSpecial = '(?=.*[' + PatternUtils.escapeRegExp(storeSpecialCharacteres) + '])';

        // lookahead minuscule et majuscule et ( chiffre ou caractère spécial)
        const lookaheadPartA = lookaheadLowercase + lookaheadUppercase + '(?=.*[' + numbers
            + PatternUtils.escapeRegExp(storeSpecialCharacteres) + ']';
        // lookahead chiffre et caractère spécial et ( minuscule ou majuscule)
        const lookaheadPart2 = lookaheadNumber + lookaheadSpecial + '(?=.*[' + lowercaseLetters
            + uppercaseLetters + ']';
        return new RegExp('(?=.{8,})(' + lookaheadPartA + ')|' + lookaheadPart2 + '))(?![.\n]).*');
    }

    /**
     * Echape tous les caractères spéciaux d'un string à destination d'une REGEX
     * @param str
     * @returns {*}
     * @private
     */
    public static escapeRegExp(str: string): string {
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    }

}
