
import '@/css/home-login.css';
import {IUsersServicesSumUpCommand} from '@/services/dto/IUsersServicesSumUpCommand';
import {Component} from 'vue-property-decorator';
import {services, store} from '@/main';
import PanelHome from '@/components/home/PanelHome.vue';
import AbstractComponentVue from '../services/AbstractComponentVue';
import AlertService from '@/utils/AlertService';
import GlobalService from '@/services/GlobalService';

@Component({
  components: {
    PanelHome,
  },
})
export default class Homepage extends AbstractComponentVue {
  private serviceADSN: IUsersServicesSumUpCommand[] = new Array();
  private serviceADNOV: IUsersServicesSumUpCommand[] = new Array();

  private mounted() {
    // remplissage des messages en mode pas affiché
    services.restService.callGet('/api/v1/mer-message/all')
        .then((response: any) => {
          this.$store.commit('savelisteMessages', response.data);
          // gestion de l'affichage des messages
          services.loginService.manageBandeauMer('display');
        });
  }

  // Fontion du cycle de vie de VUE.
  private created() {
    if (!store.getters.isHomePageAlreadyLoaded) {
      // On enregistre que la page d'accueil a déjà  été chargée une fois
      store.commit('homePageIsFirstlyLoaded');
      const globalService = new GlobalService();
      if (store.getters.isMailPasswordExpired && globalService.isAdsn()) {
        // On affiche le popup du mot de passe mail expiré
        AlertService.warning('Le mot de passe de votre messagerie a plus de 6 mois. ' +
            'Pour la sécurité de votre compte de messagerie, nous vous conseillons de le modifier.', 'Fermer');
      }
    }
  }

  /**
   * petite fonction de gestion de la page
   */
  private generatePage() {
    const buildPage = (response: any) => {
      const tableauServices: IUsersServicesSumUpCommand[] = response.data;
      for (const row of tableauServices) {
        const serviceName: string = row.serviceName;
        if (serviceName.toLowerCase().indexOf('messagerie') !== -1) {
          this.serviceADSN.push(row);
        } else {
          this.serviceADNOV.push(row);
        }
      }
    };
    services.restService.callGet('/api/v1/office/current/user-services').then(buildPage);
  }

  /**
   * hook qui est appelle avant de rendre la page
   */
  private beforeMount(): void {
    this.generatePage();
  }

  /**
   * Ajoute la liste des utilisateurs en tant que composant (qui s'auto-ouvrira).
   */
  private modifyUserList(): void {
    this.$store.commit('modifyUserListLoaded', true);
  }

  /**
   * Ajoute la liste des utilisateurs en tant que composant (qui s'auto-ouvrira).
   */
  private deleteUserList(): void {
    this.$store.commit('deleteUserListLoaded', true);
  }

}
