import router from '@/router/index';


export default class RoutageService {

	/**
	 * Affiche la page de destination.
	 * @param target path de la page de destination voulue.
	 */
	public goTo(target: string) {
		router.push({ path: target });
	}
}
