import {Vue} from 'vue-property-decorator';

export type SweetAlertIcon = 'success' | 'error' | 'warning' | 'info' | 'question';

export default class AlertService {
    public static defaultPopupTimer: number = 3000;

    /**
     * Affiche un message de type 'error'
     * @param {String} msg - le message associé à l'alerte.
     * @param {String} title - (Optionnal) Le titre de l'alerte
     *
     */
    public static error(msg?: string, title?: string) {
        Vue.prototype.$swal.fire({
            icon: 'error',
            title: title !== undefined ? title : '',
            text: msg !== undefined ? msg : '',
        });
    }

    /**
     * Affiche un message de type 'error'
     * @param {String} msg - le message associé à l'alerte.
     * @param {String} confirmButton - (Optionnal) Le titre de l'alerte
     */
    public static warning(msg?: string, confirmButton?: string) {
        Vue.prototype.$swal.fire({
            icon: 'warning',
            title: '',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: true,
            html: msg !== undefined ? '<div class="sweet-alert"><p>' + msg + '</p></div>' : '',
            confirmButtonText: confirmButton !== undefined && confirmButton !== null ? confirmButton : 'OK',
        });
    }

    /**
     * Affiche un message customizable
     *
     * @param {String} [title=''] - (Nullable) Titre de la popup
     * @param {String} [message] - (Nullable) Message de la popup
     * @param {String} type - [null, info, success, error, warning] Type de l'erreur
     * @param {String} [confirmButtonText='OK'] -
     * @param {Boolean} [isCancelButton=false] - Indique si il faut afficher le bouton "Annuler" ou non
     * @param {Function} [functionOnConfirm] - (Nullable) Fonction a exécuter lors du clic sur le bouton Confirm
     * @param {Boolean} [closeOnConfirm=false] - Indique si il faut fermer la popup en cliquant sur le bouton de
     * confirmation ou non
     * @param {Boolean} [allowEscapeKey=true] - Indique si l'utilisateur peut fermer la popup en appuyant sur echap
     *
     */
    public static customAlert(
        title?: string,
        message?: string,
        type?: SweetAlertIcon,
        confirmButtonText?: string,
        isCancelButton?: boolean,
        functionOnConfirm?: () => void,
        closeOnConfirm?: boolean,
        allowEscapeKey?: boolean,
    ) {
        // Paramétrage
        const options = {
            title: title !== undefined && title !== null ? title : '',
            text: message,
            icon: type !== undefined ? type : 'info',
            html: message,
            allowOutsideClick: false,
            showConfirmButton: confirmButtonText !== undefined && confirmButtonText !== null,
            confirmButtonText: confirmButtonText !== undefined && confirmButtonText !== null ? confirmButtonText : 'OK',
            showCancelButton: isCancelButton !== undefined && isCancelButton,
            cancelButtonText: 'Annuler',
            // closeOnCancel: true,
            // closeOnConfirm: closeOnConfirm !== undefined && closeOnConfirm,
            allowEscapeKey: allowEscapeKey !== undefined ? allowEscapeKey : true,
            reverseButtons: true,
        };

        // Affichage de la notification
        if (functionOnConfirm !== undefined) {
            Vue.prototype.$swal.fire(options)
                .then((result: any) => {
                    if (result.isConfirmed) {
                        functionOnConfirm();
                    }
                });
        } else {
            // Pas de fonction
            Vue.prototype.$swal.fire(options);
        }
    }

    /**
     * Affiche un message de type 'success'
     * @param {String} [msg] - le message associé à l'alerte.
     * @param {String} [confirmButton] - Si définit, affiche un bouton de confirmation avec le libelle associé.
     * SI non définit, n'affiche pas de bouton et ferme la popup automatiquement
     */
    public static success(msg?: string, confirmButton?: string) {
        // Affichage de la notification
        Vue.prototype.$swal.fire({
            title: '',
            text: msg !== null ? msg : 'Votre demande a bien été prise en compte.',
            icon: 'success',
            allowOutsideClick: confirmButton === undefined,
            showConfirmButton: confirmButton !== undefined && confirmButton.length > 0,
            confirmButtonText: confirmButton !== undefined ? confirmButton : '',
            timer: confirmButton !== undefined ? undefined : this.defaultPopupTimer,
            timerProgressBar: true,
        });
    }

}
