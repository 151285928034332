import AlertService from '@/utils/AlertService';

export default class ErrorUtils {

    public static msgDefault: string = 'Une erreur est survenue lors de l\'appel au serveur.' +
        ' Veuillez contacter votre administrateur';

    /**
     * Affichage du contenu de la liste de champ d'erreur (message ou code d'erreur concat�n�) dans sweetAlert.
     * @param resp contenu de l'erreur
     * @param msg message principale
     */
    public static showErrors(resp: any, msg?: string) {
        const htmlfieldErrors: string[] = [];
        if (resp !== undefined && resp.description !== undefined) {
            htmlfieldErrors.push(resp.description);
        }
        if (resp !== undefined && resp.fieldErrors !== undefined && resp.fieldErrors.length > 0) {
            resp.fieldErrors.forEach((item: any) => {
                htmlfieldErrors.push(item.message);
            });
        }
        if (htmlfieldErrors.length > 0) {
            if (msg !== undefined) {
                htmlfieldErrors.unshift(msg);
            }
            AlertService.error(htmlfieldErrors.join('\n'));
        }
    }

    /**
     * Fonction d'affichage du message d'un retour en erreur dans une popup.
     * On regarde d'abord le champ description de la r�ponse, s'il n'est pas pr�sent on regarde le champs message
     * Enfin si aucun des deux champs ne sont pr�sents on affiche le message d'erreur par d�faut
     * @param resp : La response de l'appel en erreur
     */
    public static defaultCallback(resp: any, prefix?: string, suffix?: string) {
        let errorMessage: string = '';
        if (prefix && prefix.trim().length > 0 ) {
            errorMessage += prefix;
        }

        if (resp.data.description && resp.data.description.trim().length > 0) {
            errorMessage += resp.data.description;
        } else if (resp.data.message && resp.data.message.trim().length > 0) {
            errorMessage += resp.data.message;
        } else {
            errorMessage += ErrorUtils.msgDefault;
        }

        if (suffix && suffix.trim().length > 0 ) {
            errorMessage += suffix;
        }

        AlertService.error(errorMessage);
    }
}
