
import AbstractComponentVue from '../../services/AbstractComponentVue';
import {Component} from 'vue-property-decorator';
import {services, store} from '@/main';
import UserListSelection from '@/components/account/UserListSelection.vue';
import {BNavItemDropdown} from 'bootstrap-vue';

@Component({
	components: {
		UserListSelection,
	},
})
export default class Header extends AbstractComponentVue {

  private get isConnectedUser(): boolean {
    return this.$store.getters.isConnectedUser;
  }

  private get hasAnyAuthorityMer(): boolean {
    return this.$store.getters.hasAnyAuthorityMer;
  }

  private get isRoleAdminOrNotaire(): boolean {
    return this.$store.getters.notaireOrAdmin;
  }

  private get hasAnyUserServiceBeingValid(): boolean {
    const userServiceStateTab: string[] = [];
    if (this.$store.getters.mailService !== undefined && this.$store.getters.mailService !== null) {
      userServiceStateTab.push(this.$store.getters.mailService.state);
    }
    if (this.$store.getters.balladeService !== undefined && this.$store.getters.balladeService !== null) {
      userServiceStateTab.push(this.$store.getters.balladeService.state);
    }
    if (this.$store.getters.visioService !== undefined && this.$store.getters.visioService !== null) {
      userServiceStateTab.push(this.$store.getters.visioService.state);
    }
    return userServiceStateTab.some((x) => x === 'BEING_VALIDATED');
  }

  /**
   * Redirection vers le formulaire de création utilisateur
   * (si déjà sur page de création utilisateur, init... de la page).
   */
  private goToCreatePageEmpty(): void {
    // CONTOURNEMENT Car module vue-router ne permet pas de recharge sur la même URL
    if (this.$router.currentRoute.path.indexOf('/account/create') === 0) {
      this.$router.go(0);
    } else {
      services.routageService.goTo('/account/create');
    }
  }

	/**
	 * Deconnexion
	 */
	private logout = () => {
		// On efface en dehors du disconnect pour éviter l'effacement de la valeur
		// quand l'utilisateur se trompe de login ou mdp en se loguant
		store.commit('eraseTargetUrl');
		services.loginService.disconnect();
		services.routageService.goTo('/');
	}

	/**
	 * Ajoute la liste des utilisateurs en tant que composant (qui s'auto-ouvrira).
	 */
	private modifyUserList(): void {
		this.$store.commit('modifyUserListLoaded', true);
	}
	/**
	 * Ajoute la liste des utilisateurs en tant que composant (qui s'auto-ouvrira).
	 */
	private deleteUserList(): void {
		this.$store.commit('deleteUserListLoaded', true);
	}

	private onOver(idDiv: string) {
		(this.$refs[idDiv] as BNavItemDropdown).visible = true;
	}

	private onLeave(idDiv: string) {
		(this.$refs[idDiv] as BNavItemDropdown).visible = false;
	}

}
