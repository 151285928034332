import {UserServiceState, UserServiceType} from '../services/dto/IDtos';
import {ROLE} from '@/services/dto/Roles';

export default class DisplayEnumUtils {
	/**
	 * Permet d'afficher le label du service
	 */
	public static getDisplayName(serviceType: string): string {
		if (serviceType === 'VISIO_SOFT') {
			return UserServiceType.VISIO_SOFT;
		} else if (serviceType === 'MAIL') {
			return UserServiceType.MAIL;
		} else if (serviceType === 'BALLADE') {
			return UserServiceType.BALLADE;
		} else if (serviceType === 'MER') {
			return UserServiceType.MER;
		} else if (serviceType === 'INTERNET') {
			return UserServiceType.INTERNET;
		} else {
			return serviceType;
		}
	}

	/**
	 * Permet d'afficher l'état du service
	 */
	public static getDisplayState(serviceState: string): string {
		if (serviceState === 'ACTIVE') {
			return UserServiceState.ACTIVE;
		} else if (serviceState === 'DELETION_IN_PROGRESS') {
			return UserServiceState.DELETION_IN_PROGRESS;
		} else if (serviceState === 'DELETED') {
			return UserServiceState.DELETED;
		} else if (serviceState === 'IN_CREATION') {
			return UserServiceState.IN_CREATION;
		} else if (serviceState === 'BEING_VALIDATED') {
			return UserServiceState.BEING_VALIDATED;
		} else {
			return UserServiceState.NONE;
		}
	}

	public static displayRole(role: string, admin?: boolean): string {
		switch (role) {
			case 'ROLE_SELFCARE_COLLABORATEUR': {
				if (admin !== undefined && admin) {
					return ROLE.ROLE_SELFCARE_ADMIN;
				}
				return ROLE.ROLE_SELFCARE_COLLABORATEUR;
			}
			case 'ROLE_SELFCARE_NOTAIRE': {
				return ROLE.ROLE_SELFCARE_NOTAIRE;
			}
			case 'ROLE_ENTITY': {
				return ROLE.ROLE_ENTITY;
			}
			default: {
				return role;
			}
		}
	}
}
