
export default class Config {
	public config = {
		$api_url: 'http://azerty/sig-web',
		$mer_url: 'http://azerty/mer-web',
		$mer_services_by: 'adsn',
		$directoy_visio_url: 'http://azerty/mer-web',
		timeoutDuration: 30000,
	};
}
