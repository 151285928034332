import {services} from '@/main';
import Axios from 'axios';


export default class RestService {

	/**
	 * Appel get de l'URI relatif path.
	 * @param path
	 */
	public callGet(path: string): Promise<any> {
		const api: string = services.config.config.$api_url;
		return Axios.get(api + path);
	}

	/**
	 * Appel POST de l'URI reliatif path
	 * @param path
	 * @param data Les données du post
	 */
	public callPost(path: string, data?: any): Promise<any> {
		const api: string = services.config.config.$api_url;
		if (typeof data === 'undefined') {
			return Axios.post(api + path, null);
		} else {
			return Axios.post(api + path, data);
		}
	}

	/**
	 * Appel POST de l'URI relatif path en mode transmission des données brutes
	 * @param path
	 * @param data Les données du post
	 */
	public callRawPost(path: string, data?: any): Promise<any> {
		const api: string = services.config.config.$api_url;
		return Axios.post(api + path,
        data,
        {
            headers: {
                 'Content-Type' : 'text/plain',
            },
        });
	}

	/**
	 * Appel PATCH de l'URI relatif path
	 * @param path
	 * @param data Les données du patch
	 */
	public callPatch(path: string, data: any): Promise<any> {
		const api: string = services.config.config.$api_url;
		return Axios.patch(api + path, data);
	}

	/**
	 * Appel PATCH de l'URI relatif path en mode transmission des données brutes
	 * @param path
	 * @param data Les données
	 * @param contentType Le content type
	 */
	public callPatchWithContentType(path: string, data?: any, contentType?: string): Promise<any> {
		const api: string = services.config.config.$api_url;
		return Axios.patch(api + path,
        data,
        {
            headers: {
                 'Content-Type' : contentType,
            },
        });
	}

	/**
	 * Appel PUT de l'URI relatif path
	 * @param path
	 * @param data Les données du put
	 */
	public callPut(path: string, data: any): Promise<any> {
		const api: string = services.config.config.$api_url;
		return Axios.put(api + path, data);
	}

	/**
	 * Appel PUT de l'URI relatif path en mode transmission des données brutes
	 * @param path
	 * @param data Les données
	 * @param contentType Le content type
	 */
	public callPutWithContentType(path: string, data?: any, contentType?: string): Promise<any> {
		const api: string = services.config.config.$api_url;
		return Axios.put(api + path,
        data,
        {
            headers: {
                 'Content-Type' : contentType,
            },
        });
	}

	/**
	 * Appel DELETE de l'URI relatif path
	 * @param path
	 */
	public callDelete(path: string): Promise<any> {
		const api: string = services.config.config.$api_url;
		return Axios.delete(api + path);

	}
}
