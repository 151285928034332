import {services} from '@/main';

export default class GlobalService{

    public isAdsn() {
        return (services.config.config.$mer_services_by === "adsn" || services.config.config.$mer_services_by === "all") ? true : false;
    }

    public isAdnov() {
        return (services.config.config.$mer_services_by === "adnov" || services.config.config.$mer_services_by === "all") ? true : false;
    }

    public isAll() {
        return (services.config.config.$mer_services_by === "all") ? true : false;
    }
}