
import AbstractComponentVue from '@/services/AbstractComponentVue';
import {Component} from 'vue-property-decorator';
import Header from '@/components/header/Header.vue';
import Footer from '@/components/footer/Footer.vue';
import Homepage from '@/views/Homepage.vue';
import Axios from 'axios';
import {services, store} from '@/main';
import {IMessages} from '@/services/dto/IMessages';
import AlertService from '@/utils/AlertService';
import ErrorUtils from '@/utils/ErrorUtils';
import BandeauInformation from '@/components/home/BandeauInformation.vue';

@Component({
  components: {
    BandeauInformation,
    Header,
    Homepage,
    Footer,
  },
})
export default class App extends AbstractComponentVue {
  // Les actions appelées lors de la création de l'application.
  private created() {
    // Intercepteur de toutes les requêtes
    Axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          // traitement specifique pour le login failed
          const response = error.response;
          const desc: string = response !== undefined && response.data
          && response.data.description ? response.data.description : '';
          const status = response.status;

          if (status === 401 && desc !== 'Le nom d\'utilisateur ou le mot de passe que vous avez saisi est incorrect') {
            // Il faut appeler le logout ici et vider les info de connexion de la personne connectée
            services.loginService.disconnect();

            // On redirige vers la page de login
            services.routageService.goTo('/');
            // On affiche le popup d'info de déconnexion
            AlertService.warning('Vous n\'êtes pas/plus connecté');
            return Promise.reject(error.response);
          } else if (status === 400 || status === 412) {
            ErrorUtils.defaultCallback(response);
            return Promise.reject(error.response);
          } else if (status === 500) {
            AlertService.error('Une erreur est survenue.');
            return Promise.reject(error.response);
          } else {
            return Promise.reject(error.response);
          }
        },
    );
  }

  private get listeMessages(): IMessages[] {
    return this.$store.getters.listeMessages;
  }

  /**
   * Le bouton OK du Popup d'info pour la déconnexion
   */
  private handleOk() {
    // On redirige vers la page de login
    services.routageService.goTo('/');
  }
}
