import {Vue} from 'vue-property-decorator';
import {services, store} from '@/main';
import {IMessages} from '@/services/dto/IMessages';

export default class AbstractComponentVue extends Vue {

	/**
	 * Fonction qui cache les popup modale
	 * @param modalId : Identifiant du modal à fermer.
	 */
	public hideModal(modalId: string) {
		this.$bvModal.hide(modalId);
	}

	/**
	 * Fonction qui ouvre le popup Modal.
	 * @param modalId : Identifiant du modal à ouvrir
	 */
	public showModal(modalId: string) {
		this.$bvModal.show(modalId);
	}

	public getMerServiceBy(){
		return services.config.config.$mer_services_by;
	}
}
