
import { Component, Vue, Prop } from 'vue-property-decorator';
import { store, services } from '@/main';
import { IUsersServicesSumUpCommand } from '@/services/dto/IUsersServicesSumUpCommand';

@Component({})
export default class HomeContent extends Vue {
	@Prop() private service!: IUsersServicesSumUpCommand[];
	@Prop() private brand!: string;
	@Prop() private tooltip?: string;
}
