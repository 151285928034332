import {IUserResponse} from '@/services/dto/IUserResponse';
import {IUserService} from '@/services/dto/IUserService';

export default class UserUtils {

    /**
     * Les Roles single pour les autorisations
     * - key : Valeur pour MER
     * - value : Valeur pour le SIG
     */
    public static SIG_ROLE_MER = {
        ADMIN_OFFICE: 'ROLE_SELFCARE_ADMIN',
        COLLABORATEUR: 'ROLE_SELFCARE_COLLABORATEUR',
        NOTAIRE: 'ROLE_SELFCARE_NOTAIRE',
        ENTITY: 'ROLE_ENTITY',
    };

    public static get allRolesInMER(): string[] {
        return [UserUtils.SIG_ROLE_MER.ADMIN_OFFICE, UserUtils.SIG_ROLE_MER.COLLABORATEUR,
            UserUtils.SIG_ROLE_MER.ENTITY, UserUtils.SIG_ROLE_MER.NOTAIRE];
    }

    /**
     * Renvoie la liste des fonctions des utilisateurs.
     */
    public static get allUserFonctionForSelectOptions(): object[] {
        return [
            {text: 'Clerc', value: 'CLERC'},
            {text: 'Juriste', value: 'JURISTE'},
            {text: 'Formaliste', value: 'FORMALISTE'},
            {text: 'Comptable', value: 'COMPTABLE'},
            {text: 'Standardiste', value: 'STANDARDISTE'},
            {text: 'Autre', value: 'AUTRE'},
        ];
    }

    /**
     * Permet de recherche depuis un IUserResponse le login lié de service MER
     * @param userResponse
     */
    public static findLoginInUserServices(userResponse?: IUserResponse): string | undefined {
        if (userResponse !== undefined && userResponse.userServices.length > 0) {
            const serviceMER: IUserService[] = userResponse.userServices
                .filter((us) => us.serviceType === 'MER' && us.state !== 'DELETED'
                    && us.login !== null);
            if (serviceMER.length > 0) {
                return serviceMER[0].login;
            }
        }
        return undefined;
    }

    /**
     * Permet de connaite le role d'un user
     * @param userResponse
     */
    public static isEntity(userResponse?: IUserResponse): boolean {
        if (userResponse !== undefined && userResponse.authorities !== undefined
            && userResponse.authorities.length > 0) {
            const role: string = userResponse.authorities.length > 0 ?
                userResponse.authorities[0] : '';
            return role === UserUtils.SIG_ROLE_MER.ENTITY;
        }
        return false;
    }

    /**
     * Permet de vérifier si l'utilisateur possède un rôle autorisé dans le SIG.
     * @param userResponse l'utilisateur
     * @return 'true' à un role autorisé dans MER
     */
    public static hasAnyAuthorityMer(userResponse?: IUserResponse | null): boolean {
        // Verification que IUserResponse n'est pas est chargé et qu'il a un role
        if (userResponse !== undefined && userResponse !== null
            && userResponse.authorities !== undefined && userResponse.authorities.length > 0) {
            // role à comparé
            const role = userResponse.authorities[0];
            return UserUtils.allRolesInMER.some((x: string) => role === x);
        }
        return false;
    }
}
