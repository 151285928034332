
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
})
export default class IdentityAccount extends Vue {
	@Prop() private lastName!: string;
	@Prop() private firstName!: string;
	@Prop() private login!: string;
	@Prop() private mail!: string;
}
