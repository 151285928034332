import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import {services} from '@/main';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Login',
    meta: {requiresAuth: false, notaireOrAdmin: false},
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/home',
    name: 'Home page',
    meta: {requiresAuth: true, notaireOrAdmin: false},
    component: () => import('../views/Homepage.vue'),
  },
  {
    path: '/bilan',
    name: 'Fiche bilan',
    meta: {requiresAuth: true, notaireOrAdmin: true},
    component: () => import ('../views/Bilan.vue'),
  },
  {
    path: '/account/create',
    name: 'Création d\'utilisateur',
    meta: {requiresAuth: true, notaireOrAdmin: true},
    component: () => import ('../views/UserCreation.vue'),
  },
  {
    path: '/account/list',
    name: 'Liste des utilisateurs',
    meta: {requiresAuth: true, notaireOrAdmin: true},
    component: () => import ('../views/UserList.vue'),
  },
  {
    path: '/account/:id/services',
    name: 'Détail de l\'utilisateur',
    meta: {requiresAuth: true, notaireOrAdmin: true},
    component: () => import ('../views/UserDetails.vue'),
  },
  {
    path: '/account/:id/services/MAIL/reactivate',
    name: 'Page de réactivation MAIL',
    meta: {requiresAuth: true, notaireOrAdmin: true},
    component: () => import ('../views/MailReactivation.vue'),
  },
  {
    path: '/account/:id/services/MAIL/activate',
    name: 'Page d\'activation MAIL',
    meta: {requiresAuth: true, notaireOrAdmin: true},
    component: () => import ('../views/MailActivation.vue'),
  },
  {
    path: '/account/:id/services/MAIL/deactivate',
    name: 'Page de désactivation MAIL',
    meta: {requiresAuth: true, notaireOrAdmin: true},
    component: () => import ('../views/MailDeactivation.vue'),
  },
  {
    path: '/account/:id/services/BALLADE/activate',
    name: 'Page d\'activation BALLADE',
    meta: {requiresAuth: true, notaireOrAdmin: true},
    component: () => import ('../views/BalladeActivation.vue'),
  },
  {
    path: '/account/:id/services/BALLADE/deactivate',
    name: 'Page de désactivation BALLADE',
    meta: {requiresAuth: true, notaireOrAdmin: true},
    component: () => import ('../views/BalladeDeactivation.vue'),
  },
  {
    path: '/account/:id/services/VISIO/activate',
    name: 'Page d\'activation VISIO',
    meta: {requiresAuth: true, notaireOrAdmin: true},
    component: () => import ('../views/VisioActivation.vue'),
  },
  {
    path: '/account/:id/services/VISIO/deactivate',
    name: 'Page de désactivation Visio',
    meta: {requiresAuth: true, notaireOrAdmin: true},
    component: () => import ('../views/VisioDeactivation.vue'),
  },
  {
    path: '/office/current/services/visio/credentials',
    name: 'Page de gestion visio salle',
    meta: {requiresAuth: false, notaireOrAdmin: false},
    component: () => import ('../views/VisioCredentials.vue'),
  },
  {
    path: '/account/:accountId(\\d+)/delete',
    name: 'Supprimer un compte utilisateur',
    meta: {requiresAuth: true, notaireOrAdmin: true},
    component: () => import ('../views/UserDelete.vue'),
  },
  {
    path: '/forgotten',
    name: 'Mot de passe oublié',
    meta: {requiresAuth: false, notaireOrAdmin: false},
    component: () => import ('../views/MotDePasseOublie.vue'),
  },
  {
    path: '/forgetten/notaire/:accountId(\\d+)',
    name: 'Mot de passe oublié notaire',
    meta: {requiresAuth: true},
    component: () => import ('../views/MotDePasseOublieFromNotaire.vue'),
  },
  {
    path: '/user/:login/user-services/activation/:activationCode',
    name: 'Redéfinition de mot de passe',
    meta: {requiresAuth: false},
    component: () => import ('../views/RedefinitionMotDePasse.vue'),
  },
  {
    path: '/accounts/:accountId(\\d+)/mer/activation/new',
    name: 'Nouveau code d\'activation',
    meta: {requiresAuth: true},
    component: () => import ('../views/NouveauCodeActivation.vue'),
    props: {login: '', codeActivation: ''},
  },
  {
    path: '/download/installers',
    name: 'Téléchargement des logiciels',
    meta: {requiresAuth: false, notaireOrAdmin: false},
    component: () => {
      if(Vue.prototype.isAdnov()) return import ('../views/Logiciels.vue');
      if(Vue.prototype.isAdsn() && !Vue.prototype.isAll()) return import ('../views/AccessDenied.vue');
    }
  },
  {
    path: '/download/guides/',
    name: 'Guide d\'utilisation',
    meta: {requiresAuth: false, notaireOrAdmin: false},
    props: true,
    component: () => import ('../views/UserGuide.vue'),
  },
  {
    path: '/account/personal-info/personnal-info',
    name: 'Mes informations personnelles',
    meta: {requiresAuth: true, notaireOrAdmin: false},
    alias: ['/account/personal-info/MAIL', '/account/personal-info/BALLADE', '/account/personal-info/VISIO_SOFT'],
    component: () => import ('../views/PersonalInformation.vue'),
  },
  {
    path: '/first-connexion/:login/activation/:activationCode',
    name: 'Saisir mes premières informations personnelles',
    meta: {requiresAuth: false, notaireOrAdmin: false},
    component: () => import('../views/FirstConnexion.vue'),
  },
  {
    path: '/access-denied',
    name: 'Accès interdit',
    meta: {requiresAuth: false, notaireOrAdmin: false},
    component: () => import ('../views/AccessDenied.vue'),
  },
  {
    path: '/version',
    name: 'Version',
    alias: ['/version.htm'],
    meta: {requiresAuth: false, notaireOrAdmin: false},
    component: () => import ('../views/Version.vue'),
  },
  {
    path: '/diagnostic',
    name: 'Diagnostic',
    alias: ['/diagnostic.htm'],
    meta: {requiresAuth: false, notaireOrAdmin: false},
    component: () => import ('../views/Diagnostic.vue'),
  },
  {
    path: '*',
    meta: {notfound: true, requiresAuth: false, notaireOrAdmin: false},
    name: 'Page introuvable',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/QuatreCentQuatre.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
