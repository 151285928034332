
import AbstractComponentVue from '../../services/AbstractComponentVue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { IAccountInfos } from '@/services/dto/IAccountInfos';
import IdentityAccount from '@/components/account/IdentityAccount.vue';
import {services, store} from '@/main';

@Component({
	components: {
		IdentityAccount,
	},
})
export default class UserListSelection extends AbstractComponentVue {
	// Propriété permettant de savoir s'il s'agit d'une modification ou suppression d'utilisateur
	@Prop() private isModify!: boolean;

	private accounts: IAccountInfos[] = new Array();
	private accountsFiltered: IAccountInfos[] = new Array();
	private currentPage: number = 1;
	private perPage: number = 10;
	private rows: number = 0;
	private identityFilter: string = '';
	private isBusy: boolean = false;

	private fields = [
		{
			key: 'identity',
		  	label: 'Identité',
		  	formatter: (value: any, key: any, item: any) => {
			  return item.identity.lastName + ' ' + item.identity.firstName + ' '
			  + item.identity.login + ' ' + item.identity.mail;
		  	},
		  	sortable: true,
		  	sortByFormatted: true,
		},
	];

	// Filtre sur l'identité
	@Watch('identityFilter', { immediate: true, deep: true })
	private onIdentityFilterChange(newVal: any) {
		if (newVal === '') {
			this.accountsFiltered = this.accounts.filter(
				(account) => (this.isModify || account.identity.accountId !== this.$store.getters.connectedUserAccountId));
		} else {
			this.accountsFiltered = this.accounts.filter(
				(account) => (this.isModify || account.identity.accountId !== this.$store.getters.connectedUserAccountId) &&
							(account.identity.lastName.search(new RegExp(newVal, 'i')) > -1
							|| account.identity.firstName.search(new RegExp(newVal, 'i')) > -1
							|| account.identity.login.search(new RegExp(newVal, 'i')) > -1));
		}
		this.rows = this.accountsFiltered.length;
	}

	/**
	 * Quand la fenêtre ferme, on enleve le composant du DOM (du header).
	 */
	private closePopup(): void {
		this.$store.commit('modifyUserListLoaded', false);
		this.$store.commit('deleteUserListLoaded', false);
	}

	/**
	 * Quand le composant est monté (les données chargées), on l'ouvre en tant que popup.
	 */
	private mounted() {
		if (this.isModify) {
			this.showModal('modifyUserList');
		} else {
			this.showModal('deleteUserList');
		}
	}

	private beforeMount() {
		this.isBusy = true;
		services.restService.callGet('/api/v1/office/current/users').then((response: any) => {
			this.accounts = response.data;
			// On ne peut pas se supprimer soit même mais on peut faire une modification
			// si on est suppression on peut pas s'auto supprimer
			if (!this.isModify) {
				this.accountsFiltered = this.accounts.filter(
					(account) => account.identity.accountId !== this.$store.getters.connectedUserAccountId);
			} else {
				this.accountsFiltered = this.accounts;
			}
			this.rows = this.accountsFiltered.length;
		}).finally(() => {
			this.isBusy = false;
		});
	}

	private redirectToModifyUser(accountId: number) {
		services.routageService.goTo('/account/' + accountId + '/services');
		this.hideModal('modifyUserList');
	}

	private redirectToDeleteUser(accountId: number) {
		services.routageService.goTo('/account/' + accountId + '/delete');
		this.hideModal('deleteUserList');
	}
}
