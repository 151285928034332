import {IUserResponse} from '@/services/dto/IUserResponse';
import {IMessages} from '@/services/dto/IMessages';
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import DisplayEnumUtils from '@/utils/DisplayEnumUtils';
import PatternUtils from '@/utils/PatternUtils';
import UserServicesUtils from '@/services/UserServicesUtils';
import {EnumUserServiceType} from '@/services/dto/IDtos';
import {IUserService} from '@/services/dto/IUserService';
import UserUtils from '@/utils/UserUtils';

const emptyUserResponse = {} as IUserResponse;
const wordsEmpty = [] as string[];
const listeMessagesVide: IMessages[] = [];

// Local storage géré par vuex
Vue.use(Vuex);
const store = new Vuex.Store({
    // Plugin permettant la persitence du store dans le localStorage du nav
    plugins: [createPersistedState({
        storage: window.localStorage,
    })],
    state: {
        // Les info sur l'utilisateur connecté
        userResponse: emptyUserResponse,
        // les messages d'information
        listeMessages: listeMessagesVide,
        // Lien de téléchargement GlobalProtect pour BalladeWan
        balladeInstallerLink: '',
        // L'URL de destination voulue lors d'un 401
        targetPath: '',
        // Est ce qu'on a déjà chargé le homePage (la première fois, pour afficher le popup du mot de passe mail expiré)
        homePageAlreadyLoaded: false,
        // Liste des mots interdits dans les mots de passe.
        prohibitedWords: wordsEmpty,
        // Liste des caratere spéciaux autorisé dans les MPD,
        // *** Rechargé par le webservice special-characters lors du login LoginService.storePwdSpecialCharacters
        pwdSpecialCharacters: PatternUtils.SIG_PATTERNS.password.specialcharactersDefault,
        // Message générique à afficher dans les fenêtres POPUP
        messagePopup: '',
        modifyUserListLoaded: false,
        deleteUserListLoaded: false,
        loadIdentity: false,
        vpnAdnovSoftwareLink: '',
    },
    mutations: {
        persistUserResponse(state, userResponse: IUserResponse) {
            state.userResponse = userResponse;
        },
        razCurrentUser(state) {
            state.userResponse = emptyUserResponse;
        },
        saveTargetUrl(state, target: string) {
            state.targetPath = target;
        },
        eraseTargetUrl(state) {
            state.targetPath = '';
        },
        homePageIsFirstlyLoaded(state) {
            state.homePageAlreadyLoaded = true;
        },
        homePageIsNotLoaded(state) {
            state.homePageAlreadyLoaded = false;
        },
        loadProhibitedWords(state, words: string[]) {
            state.prohibitedWords = words;
        },
        loadPwdSpecialCharacters(state, words: string) {
            state.pwdSpecialCharacters = words;
        },
        unLoadProhibitedWords(state) {
            state.prohibitedWords = wordsEmpty;
        },
        savelisteMessages(state, target: IMessages[]) {
            state.listeMessages = target;
        },
        saveBalladeInstallerLink(state, target: string) {
            state.balladeInstallerLink = target;
        },
        changeMessagePopup(state, msg: string) {
            state.messagePopup = msg;
        },
        modifyUserListLoaded(state, loaded: boolean) {
            state.modifyUserListLoaded = loaded;
        },
        deleteUserListLoaded(state, loaded: boolean) {
            state.deleteUserListLoaded = loaded;
        },
        loadIdentity(state, loaded: boolean) {
            state.loadIdentity = loaded;
        },
        loadVpnAdnovSoftwareLink(state, vpnAdnovSoftwareLink: string) {
            state.vpnAdnovSoftwareLink = vpnAdnovSoftwareLink;
        },
    },
    getters: {
        // DTO contenant toutes les informations de l'utilisateur connecté,
        // pour y accéder store.getters.fullUser ou fullName ou crpcen ...
        fullUser: (state) => state.userResponse,
        // La liste des profiles de l'utilisateur connecté
        autority: (state) => (state.userResponse.authorities != null) ? state.userResponse.authorities[0] : '',
        // La liste des messages
        listeMessages: (state) => state.listeMessages,
        // Lien de téléchargement globalProtect pour balladeWan
        balladeInstallerLink: (state) => state.balladeInstallerLink,
        // Le nom complet
        fullName: (state) => {
            const userResponse: IUserResponse = state.userResponse;
            let res = '';
            if (Object.keys(userResponse).length !== 0) {
                if (userResponse.entityName == null || userResponse.entityName === '') {
                    res = userResponse.firstName + ' ' + userResponse.lastName;
                } else {
                    res = userResponse.entityName;
                }
            }
            return res;
        },
        // Le rôle de l'utilisateur connecté
        displayedRole: (state) => {
            const userResponse: IUserResponse = state.userResponse;
            const res: string = '';
            if (Object.keys(userResponse).length !== 0 && userResponse.authorities != null) {
                const element: string = userResponse.authorities[0];
                return DisplayEnumUtils.displayRole(element, userResponse.admin);
            }
            return res;
        },
        // Est notaire ou admin
        notaireOrAdmin: (state) => {
            const userResponse: IUserResponse = state.userResponse;
            if (Object.keys(userResponse).length !== 0 && userResponse.authorities != null) {
                return userResponse.authorities[0] === 'ROLE_SELFCARE_NOTAIRE' || userResponse.admin;
            }
            return false;
        },
        // Le CRPCEN
        crpcen: (state) => state.userResponse.crpcen,
        // La destination voulue au moment d'un 401
        targetUrl: (state) => state.targetPath,
        // isUserConnected
        isConnectedUser: (state) => Object.keys(state.userResponse).length !== 0,
        // is le role est un role authorisé dans le MER
        hasAnyAuthorityMer: (state) => UserUtils.hasAnyAuthorityMer(state.userResponse),
        // Le mot de passe mail est expiré
        isMailPasswordExpired: (state) => {
            let expired = false;
            const serviceMail: IUserService = UserServicesUtils.findUserServiceByType(state.userResponse,
                EnumUserServiceType.MAIL);
            if (serviceMail.passwordExpired !== undefined) {
                expired = serviceMail.passwordExpired;
            }
            return expired;
        },
        // La page d'accueil a été déjà chargée une fois
        isHomePageAlreadyLoaded: (state) => state.homePageAlreadyLoaded,
        // Les mots interdits dans les mots de passe
        prohibitedWords: (state) => state.prohibitedWords,
        // Caractères spéciaux autorisé pour les mpds (alimenté depuis webserviceSig)
        pwdSpecialCharacters: (state) => state.pwdSpecialCharacters,
        // MailRescueEmety
        isMailRescueEmpty: (state) => state.userResponse.rescueEmail === null || state.userResponse.rescueEmail === '',
        msgMailRescueWarn : () => 'Pour poursuivre la navigation, veuillez renseigner les champs obligatoires (<span class="mandatory"></span>)',
        // Le login front office de l'utilisateur
        frontLogin: (states) => {
            let login: string = '';
            states.userResponse.userServices.forEach(
                (x) => {
                    if (x.serviceType === 'MER' && x.state === 'ACTIVE') {
                        login = x.login;
                    }
                },
            );
            return login;
        },
        isEntity: (state) => {
            const userResponse: IUserResponse = state.userResponse;
            if (Object.keys(userResponse).length !== 0 && userResponse.authorities != null) {
                const element: string = userResponse.authorities [0];
                if (element === 'ROLE_ENTITY') {
                    return true;
                }
            }
            return false;
        },
        isNotaire: (state) => {
            const userResponse: IUserResponse = state.userResponse;
            if (Object.keys(userResponse).length !== 0 && userResponse.authorities != null) {
                const element: string = userResponse.authorities [0];
                if (element === 'ROLE_SELFCARE_NOTAIRE') {
                    return true;
                }
            }
            return false;
        },
        isCollab: (state) => {
            const userResponse: IUserResponse = state.userResponse;
            if (Object.keys(userResponse).length !== 0 && userResponse.authorities != null) {
                const element: string = userResponse.authorities [0];
                if (element === 'ROLE_SELFCARE_COLLABORATEUR') {
                    return true;
                }
            }
            return false;
        },
        // Est notaire ou admin
        isAdmin: (state) => {
            const userResponse: IUserResponse = state.userResponse;
            if (Object.keys(userResponse).length !== 0 && userResponse.admin != null) {
                return userResponse.admin;
            }
            return false;
        },
        messagePopup: (state) => (state.messagePopup),
        modifyUserListLoaded: (state) => (state.modifyUserListLoaded),
        deleteUserListLoaded: (state) => (state.deleteUserListLoaded),
        loadIdentity: (state) => (state.loadIdentity),
        connectedUserAccountId: (state) => (state.userResponse.accountId),
        mailService: (state) => {
            return UserServicesUtils.findUserServiceByType(state.userResponse, EnumUserServiceType.MAIL);
        },
        balladeService: (state) => {
            return UserServicesUtils.findUserServiceByType(state.userResponse, EnumUserServiceType.BALLADE);
        },
        visioService: (state) => {
            return UserServicesUtils.findUserServiceByType(state.userResponse, EnumUserServiceType.VISIO_SOFT);
        },
        merService: (state) => {
            return UserServicesUtils.findUserServiceByType(state.userResponse, EnumUserServiceType.MER);
        },
        vpnAdnovSoftwareLink: (state) => state.vpnAdnovSoftwareLink,
    },
});

export default store;
