
/**
 * Service pour gerer les cookies dans MER
 */
export default class CookieService {

	public getCookie(cname: string) {
		const name = cname + '=';
		const decodedCookie = decodeURIComponent(document.cookie);
		const ca = decodedCookie.split(';');
		for (const res of ca) {
			let c = res;
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return '';
	}

	public setCookie(cname: string, cvalue: any, exdays: number) {
		const d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		const expires = 'expires=' + d.toUTCString();
		document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/;SameSite=strict';
	}
}
