
import {Component, Prop} from 'vue-property-decorator';
import AbstractComponentVue from '../../services/AbstractComponentVue';
import {IMessages} from '@/services/dto/IMessages';
import {services, store} from '@/main';

@Component({
  components: {
  },
})
export default class BandeauInformation extends AbstractComponentVue {
  @Prop() private listeMessages!: IMessages[];
  private dismissedAlert(idMessage: number) {

    const cookieName: string = 'listeMessagesFerme' + store.getters.fullUser.accountId;

    if (services.cookieService.getCookie(cookieName) === '') {
      services.cookieService.setCookie(cookieName, idMessage, 365);
    } else {
      const valeur = services.cookieService.getCookie(cookieName) + ',' + idMessage;
      services.cookieService.setCookie(cookieName, valeur, 365);
    }
    // au passage on cache aussi l'alert (couvre le cas du click sur le lien)
    const element = document.querySelector('#alerte' + idMessage) as HTMLElement;
    if (element !== null) {
      element.style.visibility = 'hidden';
      element.style.display = 'none';
    }
  }
}
