import moment from 'moment';

export default class DateUtils {

	public formatDate(date: Date): string {
		if (date) {
			return moment(date).format('DD/MM/YYYY  HH:mm:ss');
		} else {
			return '';
		}
	}
}
