import {Route} from 'vue-router';

export default class AuthUtils {

    /**
     * Permet de savoir si l'utilisateur doit être redirigé vers l'état 'url' après authentification.
     *
     * @param {String} path - path
     * @return {Boolean} True si le user est autorisé à être redirigé vers le state, false sinon
     */
    public static isStateRedirectedAfterAuthentication(path: string): boolean {
        const pathsWithRedirection = [
            '/account/personal-info/MAIL',
            '/account/personal-info/BALLADE',
            '/account/personal-info/VISIO_SOFT',
        ];
        const regExpStatesWithRedirection = new RegExp(pathsWithRedirection.join('|'), 'i');

        // Si ça match c'est que le state est un des state de la liste => donc autorisé
        return path.match(regExpStatesWithRedirection) !== null;
    }

    /**
     * Faut-il sauvegarder le lien, car l'utilisateur doit se connecter
     * @param to Route
     */
    public static hasToSaveUrl(to: Route): boolean {
        const hasToRedirect: boolean = AuthUtils.isStateRedirectedAfterAuthentication(to.path);
        const hasEmail: boolean = to.query !== undefined && to.query.fromEmail !== undefined
            && to.query.fromEmail === 'true';
        return hasToRedirect && hasEmail;
    }

}
