import {services, configuration, store} from '@/main';
import {IBalladeServiceRequest} from '@/services/dto/user-service/IBalladeServiceRequest';
import {IVisioSoftServiceRequest} from '@/services/dto/user-service/IVisioSoftServiceRequest';
import {ICreateMailServiceRequest} from '@/services/dto/user-service/ICreateMailServiceRequest';
import {IUserService} from '@/services/dto/IUserService';
import {IUserResponse} from '@/services/dto/IUserResponse';
import {EnumUserServiceType} from '@/services/dto/IDtos';

export default class UserServicesUtils {

    /**
     * Permet de retrouver un IUserService à partir du type et de l'utilisateur  IUserResponse
     * @param userResp IUserResponse
     * @param type (exemple:'MAIL')
     */
    public static findUserServiceByType(userResp: IUserResponse, type: EnumUserServiceType): IUserService {
        let userService: IUserService = {} as IUserService;
        if (Object.keys(userResp).length !== 0 && userResp.userServices.length > 0) {
            userResp.userServices.forEach(
                (x) => {
                    if (x.serviceType === type) {
                        userService = x;
                    }
                });
        }
        return userService;
    }

    /**
     * Web service permettant de chargé les informations de l'utilisateur.
     * @param accountId : identifiant account
     * @retrun Promise
     */
    public getAccount(accountId: number): Promise<any> {
        return services.restService.callGet('/api/v1/accounts/' + accountId);
    }

    /**
     * Web service permettant la création d'un service ballade.
     * @param balladeService : dto obj contenant les informations pour la création.
     */
    public activeBalladeAccount(balladeService: IBalladeServiceRequest): Promise<any> {
        return services.restService.callPost('/api/v1/user-services/ballade', balladeService);
    }

    /**
     * Web service check la disponibilité du login ballade.
     * @param loginBallde
     */
    public availabilityLoginBallade(loginBallde: string): Promise<any> {
        return services.restService.callGet('/api/v1/availability/login/' + loginBallde + '/BALLADE');
    }

    /**
     * Récupération du DisplayName pour l'activation du service utilisateur
     * @param accountId
     */
    public getVisioSoftInfoService(accountId: number): Promise<any> {
        return services.restService.callGet('/api/v1/user-services/visio-soft/' + accountId);
    }

    /**
     * Web service permettant la création d'un service visio.
     * @param accountId
     */
    public activeVisioAccount(visioSoftServiceRequest: IVisioSoftServiceRequest): Promise<any> {
        return services.restService.callPost('/api/v1/user-services/visio-soft/' +
            visioSoftServiceRequest.accountId + '/activate', visioSoftServiceRequest);
    }

    /**
     * Web service permettant la création d'un service mail.
     * @param accountId
     */
    public activeMailService(createMailService: ICreateMailServiceRequest): Promise<any> {
        return services.restService.callPost('/api/v1/user-services/mail/' +
            createMailService.accountId, createMailService);
    }

    /**
     * Renvoi la clé de la pièce jointe de la fiche install Ballade en fonction
     * de la règle métier suivante :
     * - Si office SDWAN ==> Clé balladeWan
     *   Sinon ==> Clé Ballade legacy
     */
    public getBalladeInstallGuideKey(): string {
        if (services.officeService.isOfficeSdwan() ) {
            return 'MER_GUIDE_INSTALLATION_BALLADEWAN';
        } else {
            return 'MER_GUIDE_INSTALLATION_TOUS_SUPPORT';
        }
    }
}
