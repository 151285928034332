import {ILogin} from './dto/ILogin';
import {IUserResponse} from './dto/IUserResponse';
import {services, store, configuration, utils} from '@/main';
import {IMessages} from '@/services/dto/IMessages';


export default class LoginService {

	public static storeProhibitedWords(userResponse: IUserResponse) {
		// On charge les mots interdits dans les mdp
		services.restService.callGet('/api/v1/passwords/' + userResponse.accountId + '/prohibited-words')
			.then((d) => {
					store.commit('loadProhibitedWords', d.data);
				},
			).catch((resp) => {
			console.warn('Une erreur lors du chargement des mots interdit.');
		});
	}

	public static storePwdSpecialCharacters() {
		services.restService.callGet('/api/v1/passwords/special-characters')
			.then((res) => {
				store.commit('loadPwdSpecialCharacters', res.data.value);
			})
			.catch((resp) => {
				console.warn('Erreur lors du chargement des caractères spéciaux autorisés');
			});
	}

	/**
	 * Fontion appelée pour connecter un utilisateur.
	 * @param data
	 */
	public connect(data: ILogin): Promise<any> {
		store.commit('unLoadProhibitedWords');
		return services.restService.callPost('/api/v1/authentication/login-new', data);
	}

	/**
	 * Fonction appelée pour déconnecter l'utilisateur.
	 */
	public disconnect() {
		services.restService.callPost('/api/v1/authentication/logout');
		// petite gestion du bandeau quand on logout
		this.manageBandeauMer('hidden');
		localStorage.clear();
		store.commit('razCurrentUser');
		store.commit('homePageIsNotLoaded');
		store.commit('unLoadProhibitedWords');
	}

	/**
	 * Fonction qui passe le bandeau à display ou pas.
	 * @param typeAffichage : hidden ou pas
	 */
	public manageBandeauMer(typeAffichage: string) {
		let visibility = 'visible';
		let display = 'flex';
		if (typeAffichage === 'hidden') {
			visibility = 'hidden';
			display = 'none';
		}
		const listeMessages: IMessages[] = store.getters.listeMessages;
		const cookieName: string = 'listeMessagesFerme' + store.getters.fullUser.accountId;
		const idMessagesDismissed = services.cookieService.getCookie(cookieName).split(',');
		let filteredMessageList: IMessages[] = listeMessages;
		for (const message of listeMessages) {
			if (!idMessagesDismissed.includes(String(message.id))) {
				const element = document.querySelector('#alerte' + message.id) as HTMLElement;
				if (element !== null) {
					element.style.visibility = visibility;
					element.style.display = display;
				}
			} else {
				filteredMessageList = filteredMessageList.filter( (mes) => mes.id !== message.id );
			}
		}
		store.commit('savelisteMessages', filteredMessageList);
	}

	/**
	 * Charge les informations sur l'utilisateur connecté quand ce dernier est connecté.
	 * @param res
	 */
	public loadUserPostProcessing(res: any) {
		localStorage.clear();
		services.loginService.storeUserResponse(res);
		const userResponse: IUserResponse = res.data;
		// On récupère la destination voulue au moment d'un 401
		const target: string = store.getters.targetUrl;
		// On efface la destination voulue enregistrée
		store.commit('eraseTargetUrl');
		LoginService.storeProhibitedWords(userResponse);
		LoginService.storePwdSpecialCharacters();
		if (userResponse.rescueEmail === null || userResponse.rescueEmail === '') {
			services.routageService.goTo('/account/personal-info/personnal-info');
		} else if (target === '') {
			// Redirige vers la page par défaut des gens loggués
			services.routageService.goTo('/home');
		} else {
			// Redirige vers la page target
			const currentPath: string = window.location.pathname;
			if (currentPath !== target) {
				services.routageService.goTo(target);
			}
		}
		if (!store.getters.listeMessages) {
			// remplissage des messages en mode pas affiché
			services.restService.callGet('/api/v1/mer-message/all')
				.then((response: any) => {
					store.commit('savelisteMessages', response.data);
				});
		}
		services.restService.callGet('/api/v1/mer-properties/ballade-software-link')
			.then((response: any) => {
				store.commit('loadVpnAdnovSoftwareLink', response.data);
		});
		store.commit('saveBalladeInstallerLink', configuration.config.$api_url + '/api/v1/attachment/notfree/exe/' + 'MER_BALLADE_EXE');
	}

	public storeUserResponse(res: any): void {
		const userResponse: IUserResponse = res.data;
		store.commit('persistUserResponse', userResponse);
	}

	/**
	 * Si l'identifiant accountId, corresponds à l'identifiant de l'utilisateur actuellement connecté.
	 * Cette fonction recharge les informations liées à l'utilisateur.
	 * Attention : Cette fonction est appelée lors de la mise à jour (création, ou suppression) d'un service utilisateurs.
	 * @param accountId Identifiant du compte ayant subi des modifications
	 * @param callback à exécuter en fin de process
	 */
	public ifCurrentUserReloadStore(accountId: number, callback?: () => void) {
		if (store.getters.connectedUserAccountId === accountId) {
			services.restService.callGet('/api/v1/connected-user/current')
				.then(services.loginService.storeUserResponse)
				.finally(() => {
					if (callback) {
						callback();
					}
				});
		} else {
			if (callback) {
				callback();
			}
		}
	}
}
