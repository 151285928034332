
export enum ROLE {
	ROLE_SELFCARE_COLLABORATEUR = 'Collaborateur',
	ROLE_SELFCARE_NOTAIRE = 'Notaire',
	ROLE_ENTITY = 'Entité',
	ROLE_ALL = 'Tous',
    ROLE_ADMIN = 'Administrateur',
    ROLE_SRV_APV = 'Service APV',
    ROLE_OBSERVATEUR = 'Observateur',
    ROLE_SRV_HOTLINE = 'Service Hotline',
    ROLE_SELFCARE_ADMIN = 'Administrateur de l\'office',
    ROLE_BATCH = 'Traitement interne',
    ROLE_OPERATEUR = 'Opérateur',
    ROLE_CONTROLEUR = 'Contrôleur',
    ROLE_SRV_CLIENT = 'Service Client',
    ROLE_VALIDATEUR_DT = 'Validateur données techniques',
    ROLE_ARCHITECTE = 'Architecte',
    ROLE_ANONYMOUS = 'Anonyme',
}
